import {initializeApp} from "firebase/app";
import {getFirestore} from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCTXHAw-TFCpHy9IPaFQhkIW5VBcJOc_x4",
  authDomain: "designplix-qr.firebaseapp.com",
  projectId: "designplix-qr",
  storageBucket: "designplix-qr.appspot.com",
  messagingSenderId: "643088005346",
  appId: "1:643088005346:web:9c62201dd6e8d7f9d4c6bb",
  measurementId: "G-Q5BG26QBG8",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const storage = getStorage(app);

export {db,storage};
