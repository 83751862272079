import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import QRGenerator from "./components/QRGenerator";
import Display from "./components/Display";
import UrlQRGenerator from "./components/UrlQRGenerator";
import WhatsAppQRGenerator from "./components/WhatsAppQRGenerator";
import "./App.css";
import MainHeader from "./components/MainHeader";
import Footer from "./components/Footer";

function App() {
  return (
    <Router>
      <MainHeader />
      <Routes>
        <Route path="/" element={<QRGenerator />} />
        <Route path="/display" element={<Display />} />
        <Route path="/urlqr" element={<UrlQRGenerator />} />
        <Route path="/whatsappqr" element={<WhatsAppQRGenerator />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
