import React, {useState, useRef, useEffect} from "react";
import {Container, TextField, Button, Box, Typography, Grid, Paper, CssBaseline, useMediaQuery} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import QRCodeStyling from "qr-code-styling";
import jsPDF from "jspdf";
import styled from "styled-components";
import {db} from "./firebase";
import {collection, addDoc} from "firebase/firestore";
import SecondHeader from "./SecondHeader";
import {saveAs} from "file-saver";
import whatsappIcon from "../assets/whatsapp-icon.svg"; // Import the WhatsApp icon

const StyledContainer = styled(Container)`
  height: 900px;
  width: 1200px !important;
  padding: 20px;
  border-radius: 10px;
  @media (max-width: 1200px) {
    width: auto !important;
    height: 900px;
    padding: 10px;
  }
`;

const StyledPaper = styled(Paper)`
  padding: 20px;
  margin-top: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  @media (max-width: 1200px) {
    padding: 10px;
  }
`;

const StyledButton = styled(Button)`
  background-color: #5956e9 !important;
  margin-top: 20px !important;
  color: white;
  &:hover {
    background-color: #45a049;
  }
`;

const QRCodeContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 250px;
  border: 1px dashed #5956e9;
  position: relative;
`;

const PlaceholderText = styled(Typography)`
  position: absolute;
  text-align: center;
  color: #999;
`;

const WhatsAppQRGenerator = () => {
  const isMobile = useMediaQuery("(max-width:600px)");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [color, setColor] = useState("#464ea4");
  const [qrValue, setQrValue] = useState("");
  const qrCodeRef = useRef(null);
  const qrCode = useRef(
    new QRCodeStyling({
      width: 280,
      height: 280,
      dotsOptions: {color: "#000000", type: "square"},
      image: whatsappIcon,
      imageOptions: {
        crossOrigin: "anonymous",
        margin: 10, // margin between QR code and image
      },
    })
  );

  useEffect(() => {
    if (qrCodeRef.current) {
      qrCode.current.append(qrCodeRef.current);
    }
  }, []);

  useEffect(() => {
    if (qrValue) {
      qrCode.current.update({
        data: qrValue,
        dotsOptions: {color, type: "square"},
        image: whatsappIcon, // Ensure the image is updated
      });
    }
  }, [qrValue, color]);

  const handleGenerate = async () => {
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    setQrValue(whatsappUrl);

    try {
      await addDoc(collection(db, "whatsappQRCodes"), {
        phoneNumber,
        message,
        whatsappUrl,
        color,
      });
    } catch (error) {
      console.error("Error storing data: ", error);
    }
  };

  const downloadQRCode = async (format) => {
    try {
      if (format === "pdf") {
        const svgData = await qrCode.current.getRawData("svg");
        const svgBlob = new Blob([svgData], {type: "image/svg+xml"});
        const imgUrl = URL.createObjectURL(svgBlob);

        const img = new Image();
        img.src = imgUrl;

        img.onload = () => {
          const canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);

          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF();
          pdf.addImage(imgData, "PNG", 15, 40, 180, 180);
          pdf.save("qr-code.pdf");
        };
      } else if (format === "eps" || format === "svg") {
        const svgData = await qrCode.current.getRawData("svg");
        const svgBlob = new Blob([svgData], {type: "image/svg+xml"});
        saveAs(svgBlob, `qr-code.${format}`);
      } else {
        qrCode.current.download({name: "qr-code", extension: format});
      }
    } catch (error) {
      console.error("Error generating QR code:", error);
    }
  };

  return (
    <>
      <CssBaseline />
      <StyledContainer maxWidth="lg">
        <StyledPaper elevation={3}>
          <SecondHeader />
          <Typography
            variant="h6"
            align="left"
            gutterBottom
            style={{
              display: "flex",
              paddingTop: "50px",
              paddingLeft: "20px",
              fontFamily: "rubik",
            }}>
            ENTER CONTENT
          </Typography>
          <Grid container spacing={3} style={{paddingLeft: "20px", paddingBottom: "40px"}}>
            <Grid item xs={12} md={6}>
              <Box component="form" autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <PhoneInput country={"ae"} value={phoneNumber} required onChange={(phone) => setPhoneNumber(phone)} inputStyle={{width: "100%", height: "50px"}} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth label="Message" variant="outlined" id="message" name="message" multiline rows={4} value={message} onChange={(e) => setMessage(e.target.value)} />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography variant="h6" align="left" gutterBottom style={{fontFamily: "rubik", display: "flex"}}>
                      Choose QR Color
                    </Typography>
                    <div style={{display: "flex", alignItems: "center"}}>
                      <input
                        type="color"
                        id="color"
                        name="color"
                        value={color}
                        onChange={(e) => setColor(e.target.value)}
                        style={{
                          width: "40%",
                          height: "60px",
                          cursor: "pointer",
                          padding: "0",
                          border: "none",
                          margin: "0",
                        }}
                      />
                      <input
                        type="text"
                        value={color}
                        readOnly
                        style={{
                          height: "55px",
                          cursor: "pointer",
                          border: "1px solid #ccc",
                          textAlign: "center",
                          width: "60%",
                          outline: "none",
                          padding: "0",
                          margin: "0",
                          borderLeft: "none",
                        }}
                        onFocus={(e) => (e.target.style.borderColor = "#ccc")}
                        onBlur={(e) => (e.target.style.borderColor = "#ccc")}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <QRCodeContainer
                sx={{
                  marginLeft: {xs: "2px", sm: "110px"},
                  marginBottom: {xs: "10px", sm: "10px"},
                }}>
                <div
                  ref={qrCodeRef}
                  style={{
                    marginTop: "40px",
                    marginBottom: "30px",
                    marginLeft: "8px",
                  }}></div>
                {!qrValue && (
                  <PlaceholderText variant="body1" style={{fontFamily: "rubik"}}>
                    Your QR will appear here
                  </PlaceholderText>
                )}
              </QRCodeContainer>
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    marginLeft: {xs: "1px", sm: "70px"},
                  }}>
                  <StyledButton fullWidth={false} variant="contained" onClick={handleGenerate}>
                    Generate QR
                  </StyledButton>
                </Grid>
              </Grid>
              {qrValue && (
                <Box mt={2} display="flex" justifyContent={isMobile ? "space-between" : "space-around"} width="80%">
                  <Button
                    variant="contained"
                    style={{backgroundColor: "#5956E9"}}
                    onClick={() => downloadQRCode("png")}
                    sx={{
                      marginLeft: {xs: "0px", sm: "90px"},
                    }}>
                    .PNG
                  </Button>
                  <Button
                    variant="contained"
                    style={{backgroundColor: "#5956E9"}}
                    onClick={() => downloadQRCode("svg")}
                    sx={{
                      marginLeft: {xs: "10px", sm: "0px"},
                    }}>
                    .SVG
                  </Button>
                  <Button
                    variant="contained"
                    style={{backgroundColor: "#5956E9"}}
                    onClick={() => downloadQRCode("pdf")}
                    sx={{
                      marginLeft: {xs: "10px", sm: "0px"},
                    }}>
                    .PDF
                  </Button>
                  <Button
                    variant="contained"
                    style={{backgroundColor: "#5956E9"}}
                    onClick={() => downloadQRCode("eps")}
                    sx={{
                      marginLeft: {xs: "10px", sm: "0px"},
                    }}>
                    .EPS
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>
        </StyledPaper>
      </StyledContainer>
    </>
  );
};

export default WhatsAppQRGenerator;
