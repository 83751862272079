import React, {useState, useRef, useEffect} from "react";
import {Container, TextField, Button, Box, Typography, Grid, Paper, CssBaseline, useMediaQuery} from "@mui/material";
import {useDropzone} from "react-dropzone";
import QRCodeStyling from "qr-code-styling";
import {jsPDF} from "jspdf";
import {storage, db} from "./firebase"; // Ensure correct path
import {ref, uploadBytes, getDownloadURL} from "firebase/storage"; // Import Firebase functions
import {collection, addDoc} from "firebase/firestore";
import styled from "styled-components";
import {saveAs} from "file-saver"; // Ensure this is imported
import SecondHeader from "./SecondHeader";

const StyledContainer = styled(Container)`
  height: 900px;
  width: 1200px !important;
  padding: 20px;
  border-radius: 10px;
  @media (max-width: 1200px) {
    width: auto !important;
    height: 900px;
    padding: 10px;
  }
`;

const StyledPaper = styled(Paper)`
  padding: 20px;
  margin-top: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

const StyledButton = styled(Button)`
  background-color: #5956e9 !important;
  margin-top: 20px !important;
  color: white;
  &:hover {
    background-color: #45a049;
  }
`;

const QRCodeContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 250px;
  border: 1px dashed #5956e9;
  position: relative;
`;

const PlaceholderText = styled(Typography)`
  position: absolute;
  text-align: center;
  color: #999;
`;

const UrlQRGenerator = () => {
  const isMobile = useMediaQuery("(max-width:600px)");

  const [formData, setFormData] = useState({
    url: "",
    color: "#464ea4",
    logo: null,
  });

  const [qrValue, setQrValue] = useState("");
  const qrCodeRef = useRef(null);
  const qrCode = useRef(
    new QRCodeStyling({
      width: 280,
      height: 280,
      type: "svg", // Generate as SVG to keep it vector-based
      dotsOptions: {color: "#000000", type: "square"},
      imageOptions: {crossOrigin: "anonymous"},
    })
  );

  useEffect(() => {
    if (qrCodeRef.current) {
      qrCode.current.append(qrCodeRef.current);
    }
  }, []);

  useEffect(() => {
    if (qrValue) {
      qrCode.current.update({
        data: qrValue,
        dotsOptions: {color: formData.color, type: "square"},
        image: formData.logo ? URL.createObjectURL(formData.logo) : undefined,
      });
    }
  }, [qrValue, formData]);

  const handleChange = (e) => {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value});
  };

  const handleDrop = (acceptedFiles) => {
    setFormData({...formData, logo: acceptedFiles[0]});
  };

  const uploadLogo = async (file) => {
    try {
      const logoRef = ref(storage, `logos/${file.name}`);
      await uploadBytes(logoRef, file);
      const downloadURL = await getDownloadURL(logoRef);
      return downloadURL;
    } catch (error) {
      console.error("Error uploading logo:", error);
      throw error;
    }
  };

  const handleGenerate = async () => {
    setQrValue(formData.url);

    if (formData.logo) {
      try {
        const downloadURL = await uploadLogo(formData.logo);
        await addDoc(collection(db, "userGeneratedQRs"), {
          ...formData,
          logo: downloadURL,
        });
      } catch (error) {
        console.error("Error storing data: ", error);
      }
    } else {
      try {
        await addDoc(collection(db, "userGeneratedQRs"), formData);
      } catch (error) {
        console.error("Error storing data: ", error);
      }
    }
  };

  const downloadQRCode = async (format) => {
    try {
      if (format === "pdf") {
        const svgData = await qrCode.current.getRawData("svg");
        const svgBlob = new Blob([svgData], {type: "image/svg+xml"});
        const imgUrl = URL.createObjectURL(svgBlob);

        const img = new Image();
        img.src = imgUrl;

        img.onload = () => {
          const canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);

          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF();
          pdf.addImage(imgData, "PNG", 15, 40, 180, 180);
          pdf.save("qr-code.pdf");
        };
      } else if (format === "eps") {
        const svgData = await qrCode.current.getRawData("svg");
        const svgBlob = new Blob([svgData], {type: "image/svg+xml"});
        saveAs(svgBlob, "qr-code.eps");
      } else {
        qrCode.current.download({name: "qr-code", extension: format});
      }
    } catch (error) {
      console.error("Error generating QR code:", error);
    }
  };

  const {getRootProps, getInputProps} = useDropzone({onDrop: handleDrop});

  return (
    <>
      <CssBaseline />
      <StyledContainer maxWidth="lg">
        <StyledPaper elevation={3}>
          <SecondHeader />
          <Typography
            variant="h6"
            align="left"
            gutterBottom
            style={{
              display: "flex",
              paddingTop: "50px",
              paddingLeft: "20px",
              fontFamily: "rubik",
            }}>
            ENTER CONTENT
          </Typography>
          <Grid container spacing={3} style={{paddingLeft: "20px", paddingBottom: "40px"}}>
            <Grid item xs={12} md={6}>
              <Box component="form" noValidate autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField fullWidth label="Enter Website URL" variant="outlined" id="url" name="url" type="url" value={formData.url} onChange={handleChange} />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography variant="h6" align="left" gutterBottom style={{fontFamily: "rubik", display: "flex"}}>
                      Choose QR Color
                    </Typography>
                    <div style={{display: "flex", alignItems: "center"}}>
                      <input
                        type="color"
                        id="color"
                        name="color"
                        value={formData.color}
                        onChange={handleChange}
                        style={{
                          width: "40%",
                          height: "60px",
                          cursor: "pointer",
                          padding: "0",
                          border: "none",
                          margin: "0",
                        }}
                      />
                      <input
                        type="text"
                        value={formData.color}
                        readOnly
                        style={{
                          height: "55px",
                          cursor: "pointer",
                          border: "1px solid #ccc",
                          textAlign: "center",
                          width: "60%",
                          outline: "none",
                          padding: "0",
                          margin: "0",
                          borderLeft: "none",
                        }}
                        onFocus={(e) => (e.target.style.borderColor = "#ccc")}
                        onBlur={(e) => (e.target.style.borderColor = "#ccc")}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div
                      {...getRootProps({className: "dropzone"})}
                      style={{
                        border: "1px dashed #5956E9",
                        padding: "10px",
                        textAlign: "left",
                        cursor: "pointer",
                        width: "100%",
                      }}>
                      <input {...getInputProps()} />
                      {formData.logo ? (
                        <Typography variant="body1">{formData.logo.name}</Typography>
                      ) : (
                        <Typography
                          variant="h6"
                          align="left"
                          gutterBottom
                          style={{
                            fontFamily: "rubik",
                            display: "flex",
                            alignItems: "center",
                          }}>
                          <img
                            src="icon3.png"
                            alt=""
                            style={{
                              backgroundColor: "#5956E9",
                              marginRight: "10px",
                              height: "30px",
                              width: "30px",
                            }}
                          />
                          Add Your Logo Image
                        </Typography>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <QRCodeContainer
                sx={{
                  marginLeft: {xs: "8px", sm: "110px"},
                  marginTop: {xs: "10px", sm: "0px"},
                  marginBottom: {xs: "10px", sm: "0px"},
                }}>
                <div ref={qrCodeRef}></div>
                {!qrValue && (
                  <PlaceholderText variant="body1" style={{fontFamily: "rubik"}}>
                    Your QR will appear here
                  </PlaceholderText>
                )}
              </QRCodeContainer>
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    marginLeft: {xs: "0px", sm: "70px"},
                    marginTop: {xs: "0px", sm: "10px"},
                  }}>
                  <StyledButton fullWidth={false} variant="contained" onClick={handleGenerate}>
                    Generate QR
                  </StyledButton>
                </Grid>
              </Grid>
              {qrValue && (
                <Box mt={2} display="flex" justifyContent={isMobile ? "space-between" : "space-around"} width="80%">
                  <Button
                    variant="contained"
                    style={{backgroundColor: "#5956E9"}}
                    onClick={() => downloadQRCode("png")}
                    sx={{
                      marginLeft: {xs: "0px", sm: "90px"},
                    }}>
                    .PNG
                  </Button>
                  <Button
                    variant="contained"
                    style={{backgroundColor: "#5956E9"}}
                    onClick={() => downloadQRCode("svg")}
                    sx={{
                      marginLeft: {xs: "10px", sm: "0px"},
                    }}>
                    .SVG
                  </Button>
                  <Button
                    variant="contained"
                    style={{backgroundColor: "#5956E9"}}
                    onClick={() => downloadQRCode("pdf")}
                    sx={{
                      marginLeft: {xs: "10px", sm: "0px"},
                    }}>
                    .PDF
                  </Button>
                  <Button
                    variant="contained"
                    style={{backgroundColor: "#5956E9"}}
                    onClick={() => downloadQRCode("eps")}
                    sx={{
                      marginLeft: {xs: "10px", sm: "0px"},
                    }}>
                    .EPS
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>
        </StyledPaper>
      </StyledContainer>
    </>
  );
};

export default UrlQRGenerator;
