import * as React from "react";
import Box from "@mui/material/Box";
// import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {Typography, useMediaQuery} from "@mui/material";
// import {css, keyframes} from "@emotion/react";

const Footer = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <Box style={{backgroundColor: "#383841", color: "white", paddingTop: "20px", fontFamily: "Rubik", marginTop: "80px"}}>
      <Box
        sx={{flexGrow: 1}}
        style={{
          paddingTop: "70px",
          margin: "auto",

          width: isMobile ? "auto" : "1300px",
        }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} order={{xs: 3, sm: 1}}>
            <Typography
              style={{
                fontSize: isMobile ? "17px" : "20px",
                marginLeft: isMobile ? "25px" : "40px",
                marginRight: isMobile ? "25x" : "0px",
                paddingBottom: isMobile ? "20px" : "0px",
                fontWeight: "500",
              }}>
              {" "}
              Unleash your creativity with DesignPlix, where innovation meets excellence in print and design.🤝
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5} order={{xs: 2, sm: 1}}>
            <ul
              alt=""
              style={{
                fontWeight: "400",
                listStyle: "none",
                marginRight: isMobile ? "70px" : "0px",
                marginLeft: isMobile ? "50px" : "80px",
                textAlign: isMobile ? "center" : "",
              }}>
              <Typography variant="h5" style={{fontWeight: "600", fontFamily: "Rubik", marginBottom: "5px", marginTop: isMobile ? "0px" : "-40px"}}>
                Quick Links
              </Typography>
              <li
                style={{
                  fontSize: "18px",
                  color: "white",
                }}>
                <a
                  href="https://designplix.com/card-templates"
                  style={{
                    color: "white",
                    textDecoration: "none",
                  }}>
                  Digital Business Cards
                </a>
              </li>
              <li
                style={{
                  fontSize: "18px",
                }}>
                <a
                  href="https://designplix-qr.web.app/"
                  style={{
                    color: "white",
                    textDecoration: "none",
                  }}>
                  Generate QR Code
                </a>
              </li>
              <li
                style={{
                  fontSize: "18px",
                }}>
                <a
                  href="https://designplix.com/contact"
                  style={{
                    color: "white",
                    textDecoration: "none",
                  }}>
                  Contact
                </a>
              </li>
              <li
                style={{
                  fontSize: "18px",
                }}>
                <a
                  href="https://designplix.com/blogs"
                  style={{
                    color: "white",
                    textDecoration: "none",
                  }}>
                  Blogs
                </a>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} sm={3} order={{xs: 1, sm: 2}}>
            <img
              src="footer-img.png"
              alt=""
              style={{
                width: isMobile ? "40%" : "65%",
                marginLeft: isMobile ? "120px" : "70px",
                marginTop: isMobile ? "-40px" : "-50px",
                marginBottom: isMobile ? "20px" : "0px",
              }}
            />
          </Grid>
        </Grid>
        <hr />
      </Box>
      <Box
        sx={{flexGrow: 1}}
        style={{
          margin: "auto",
          width: isMobile ? "auto" : "1300px",
        }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={5}>
            {" "}
            <a href="https://designplix.com/">
              <img
                src="logo-footer.png"
                alt=""
                style={{
                  width: "200px",
                  boxShadow: "none",

                  marginTop: isMobile ? "10px" : "20px",
                  marginLeft: isMobile ? "90px" : "20px",
                }}
              />
            </a>
          </Grid>
          <Grid
            item
            xs={12}
            sm={7}
            style={{
              paddingTop: isMobile ? "0px" : "30px",
              paddingBottom: isMobile ? "0px" : "0px",
            }}>
            <ul
              alt=""
              style={{
                display: "flex",
                fontWeight: "400",
                justifyContent: isMobile ? "space-around" : "space-between",
                listStyle: "none",
                marginLeft: isMobile ? "0px" : "370px",
                marginRight: isMobile ? "0px" : "35px",
                paddingLeft: isMobile ? "10px" : "0px",
                paddingRight: isMobile ? "10px" : "0px",
              }}>
              <li
                style={{
                  fontSize: "18px",
                  color: "white",
                }}>
                <a
                  href="https://designplix.com/terms-conditions"
                  style={{
                    color: "white",
                    textDecoration: "none",
                  }}>
                  Terms & Conditions
                </a>
              </li>
              <li
                style={{
                  fontSize: "18px",
                }}>
                <a
                  href="https://designplix.com/privacy-policy"
                  style={{
                    color: "white",
                    textDecoration: "none",
                  }}>
                  Privacy Policy
                </a>
              </li>
            </ul>
          </Grid>
        </Grid>
        <hr />
      </Box>

      {/* .............icons........... */}
      <Box
        sx={{flexGrow: 1}}
        style={{
          margin: "auto",
          width: isMobile ? "auto" : "1300px",
        }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={5}>
            <Typography
              style={{
                marginLeft: isMobile ? "0px" : "30px",
                marginTop: isMobile ? "0px" : "20px",
                textAlign: isMobile ? "center" : "",
              }}>
              {" "}
              @DesignPlix by{" "}
              <a href="https://humd.co/" style={{textDecoration: "none", color: "white", fontWeight: "600"}}>
                {" "}
                HUMD{" "}
              </a>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={7}
            style={{
              paddingTop: isMobile ? "0px" : "20px",
            }}>
            <ul
              alt=""
              style={{
                display: "flex",
                alignItems: "end",
                listStyle: "none",
                marginLeft: isMobile ? "0px" : "560px",
                marginRight: isMobile ? "0px" : "30px",
                justifyContent: "center",
              }}>
              <li>
                <a
                  href="https://www.instagram.com/designplixofficial/"
                  target="_blank"
                  style={{
                    color: "white",
                    textDecoration: "none",
                  }}
                  rel="noreferrer">
                  <img src="insta.png" alt="" style={{width: "45px"}} />
                </a>
              </li>
              <li>
                <a
                  href="https://wa.me/971504630141"
                  target="_blank"
                  style={{
                    color: "white",
                    textDecoration: "none",
                    marginLeft: isMobile ? "20px" : "20px",
                    marginRight: isMobile ? "20px" : "0px",
                  }}
                  rel="noreferrer">
                  <img src="Whatapp.png" alt="" style={{width: "45px"}} />
                </a>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Footer;
