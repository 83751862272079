import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";

const pages = [
  {name: "Digital Business Cards", link: "https://designplix.com/card-templates"},
  {name: "Blogs", link: "https://designplix.com/blogs"},
  {name: "Contact", link: "https://designplix.com/contact"},
];

function MainHeader() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static" style={{backgroundColor: "white"}} id="mainheader">
      <Container maxWidth="xl">
        <Toolbar>
          <Box sx={{display: "flex", alignItems: "center", flexGrow: 1, marginLeft: {xs: 0, md: "110px"}}}>
            {/* Logo on the left side */}
            <Typography variant="h5" noWrap component="a" href="https://designplix.com/" sx={{display: "flex", alignItems: "center"}}>
              <img src="logo-white.png" alt="" style={{width: "150px", boxShadow: "none"}} />
            </Typography>
          </Box>

          {/* Mobile version menu icon */}
          <Box sx={{display: {xs: "flex", md: "none"}, alignItems: "center"}}>
            <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu} style={{color: "black"}}>
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: {xs: "block", md: "none"},
              }}>
              {pages.map((page) => (
                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">
                    <a href={page.link} style={{textDecoration: "none", color: "inherit", fontWeight: "600", fontFamily: "Rubik"}}>
                      {page.name}
                    </a>
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          {/* Desktop version menu buttons */}
          <Box sx={{display: {xs: "none", md: "flex"}, alignItems: "center", marginRight: {xs: 0, md: "100px"}}}>
            {pages.map((page) => (
              <Button key={page.name} onClick={handleCloseNavMenu} variant="h5" sx={{my: 2, color: "black", textTransform: "capitalize"}}>
                <a href={page.link} style={{textDecoration: "none", color: "inherit", fontSize: "16px", fontFamily: "Rubik"}} target="_blank" rel="noreferrer">
                  {page.name}
                </a>
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default MainHeader;
