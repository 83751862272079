import * as React from "react";
// import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {Container, Typography, Link as MuiLink} from "@mui/material";
import {useLocation} from "react-router-dom";

const SecondHeader = () => {
  const location = useLocation();

  const getLinkStyle = (path) => {
    return {
      textDecoration: "none",
      fontSize: "16px",
      fontWeight: "600",
      color: location.pathname === path ? "white" : "black",
      borderRadius: "5px",
    };
  };

  const getGridItemBackgroundColor = (path) => {
    return location.pathname === path ? "#5956e9" : "#F9FAFB";
  };

  return (
    <Container
      style={{
        paddingTop: "40px",
        display: "flex",
        justifyContent: "center!important",
      }}>
      <Box sx={{flexGrow: 1}}>
        <Grid container spacing={2} style={{textAlign: "center"}}>
          <Grid
            item
            xs={3} // 4 columns on mobile
            sm={3} // 3 columns on small screens and above
            component={MuiLink}
            href="/"
            style={getLinkStyle("/")}
            sx={{
              backgroundColor: getGridItemBackgroundColor("/"),
              borderRadius: "10px",
              border: "1px solid #5956E9",
              paddingRight: {xs: "40px"},
              paddingLeft: {xs: "0px"},
              marginLeft: {xs: "17px", sm: "14px"}, // Apply margin on small screens and above
            }}>
            <Typography style={{paddingBottom: "10px"}}>VCard QR</Typography>
          </Grid>
          <Grid
            item
            xs={3} // 4 columns on mobile
            sm={3} // 3 columns on small screens and above
            component={MuiLink}
            href="/urlqr"
            style={getLinkStyle("/urlqr")}
            sx={{
              backgroundColor: getGridItemBackgroundColor("/urlqr"),
              borderRadius: "10px",
              border: "1px solid #5956E9",
              paddingRight: {xs: "20px"},
              marginLeft: {xs: "10px", sm: "10px"}, // Apply margin on small screens and above
              marginRight: {xs: "10px", sm: "10px"}, // Apply margin on small screens and above
            }}>
            <Typography syle={{textAlign: "text-center"}}>URL QR</Typography>
          </Grid>
          <Grid
            item
            xs={3} // 4 columns on mobile
            sm={3} // 3 columns on small screens and above
            component={MuiLink}
            href="/whatsappqr"
            style={getLinkStyle("/whatsappqr")}
            sx={{
              backgroundColor: getGridItemBackgroundColor("/whatsappqr"),
              borderRadius: "10px",
              paddingRight: {xs: "80px", sm: "0px"},
              paddingLeft: {xs: "10px", sm: "0px"},
              border: "1px solid #5956E9",
            }}>
            <Typography>Whatsapp QR</Typography>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default SecondHeader;
