import React from "react";
import { Container, Typography, Box, Paper, Button } from "@mui/material";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import jsPDF from "jspdf";

const StyledContainer = styled(Container)`
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
`;

const StyledPaper = styled(Paper)`
  padding: 20px;
  margin-top: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

const Field = styled(Typography)`
  margin-bottom: 10px;
`;

const FieldValue = styled(Typography)`
  font-weight: bold;
  font-size: 1.2em; /* Increase this value to make it even bigger */
`;

const DisplayPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const formData = {};
  for (let pair of searchParams.entries()) {
    formData[pair[0]] = pair[1];
  }

  const ensureUrlScheme = (url) => {
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      return `http://${url}`;
    }
    return url;
  };

  const saveAsVCard = () => {
    const vCardData = `
BEGIN:VCARD
VERSION:3.0
N:${formData.lastname || ""};${formData.firstname || ""};;;
FN:${formData.firstname || ""} ${formData.lastname || ""}
ORG:${formData.organization || ""}
TITLE:${formData.designation || ""}
TEL;TYPE=WORK,VOICE:${formData.phone1 || ""}
TEL;TYPE=CELL,VOICE:${formData.phone2 || ""}
EMAIL:${formData.email || ""}
URL:${formData.website || ""}
ADR;TYPE=WORK,PREF:;;${formData.address || ""}
URL;TYPE=googleMap:${formData.googleMapURL || ""}
URL;TYPE=facebook:${formData.facebook || ""}
URL;TYPE=twitter:${formData.twitter || ""}
URL;TYPE=instagram:${formData.instagram || ""}
URL;TYPE=linkedin:${formData.linkedin || ""}
END:VCARD
    `;
    const blob = new Blob([vCardData.trim()], { type: "text/vcard" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${formData.firstname || "Contact"}_${
      formData.lastname || "Info"
    }.vcf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const saveAsPDF = () => {
    const doc = new jsPDF();
    let y = 10;

    const addFieldToPDF = (label, value) => {
      if (value) {
        doc.text(`${label}: ${value}`, 10, y);
        y += 10;
      }
    };

    addFieldToPDF("First Name", formData.firstname);
    addFieldToPDF("Last Name", formData.lastname);
    addFieldToPDF("Designation", formData.designation);
    addFieldToPDF("Organization", formData.organization);
    addFieldToPDF("Phone 1", `+${formData.phone1}`);
    addFieldToPDF("Phone 2", `+${formData.phone2}`);
    addFieldToPDF("Email", formData.email);
    addFieldToPDF("Website", formData.website);
    addFieldToPDF("Address", formData.address);
    addFieldToPDF("Google Map URL", formData.googleMapURL);
    addFieldToPDF("Facebook", formData.facebook);
    addFieldToPDF("Twitter", formData.twitter);
    addFieldToPDF("Instagram", formData.instagram);
    addFieldToPDF("LinkedIn", formData.linkedin);

    doc.save(`${formData.firstname}_${formData.lastname}.pdf`);
  };

  return (
    <StyledContainer maxWidth="md">
      <StyledPaper elevation={3}>
        <Typography variant="h4" align="center" gutterBottom>
          User Information
        </Typography>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          {formData.firstname && (
            <Field>
              First Name: <FieldValue>{formData.firstname}</FieldValue>
            </Field>
          )}
          {formData.lastname && (
            <Field>
              Last Name: <FieldValue>{formData.lastname}</FieldValue>
            </Field>
          )}
          {formData.designation && (
            <Field>
              Designation: <FieldValue>{formData.designation}</FieldValue>
            </Field>
          )}
          {formData.organization && (
            <Field>
              Organization: <FieldValue>{formData.organization}</FieldValue>
            </Field>
          )}
          {formData.phone1 && (
            <Field>
              Phone 1:{" "}
              <FieldValue>
                <a href={`tel:+${formData.phone1}`}>{`+${formData.phone1}`}</a>
              </FieldValue>
            </Field>
          )}
          {formData.phone2 && (
            <Field>
              Phone 2:{" "}
              <FieldValue>
                <a href={`tel:+${formData.phone2}`}>{`+${formData.phone2}`}</a>
              </FieldValue>
            </Field>
          )}
          {formData.email && (
            <Field>
              Email:{" "}
              <FieldValue>
                <a href={`mailto:${formData.email}`}>{formData.email}</a>
              </FieldValue>
            </Field>
          )}
          {formData.website && (
            <Field>
              Website:{" "}
              <FieldValue>
                <a
                  href={ensureUrlScheme(formData.website)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {formData.website}
                </a>
              </FieldValue>
            </Field>
          )}
          {formData.address && (
            <Field>
              Address: <FieldValue>{formData.address}</FieldValue>
            </Field>
          )}
          {formData.googleMapURL && (
            <Field>
              Google Map URL:{" "}
              <FieldValue>
                <a
                  href={ensureUrlScheme(formData.googleMapURL)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {formData.googleMapURL}
                </a>
              </FieldValue>
            </Field>
          )}
          {formData.facebook && (
            <Field>
              Facebook:{" "}
              <FieldValue>
                <a
                  href={ensureUrlScheme(formData.facebook)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {formData.facebook}
                </a>
              </FieldValue>
            </Field>
          )}
          {formData.twitter && (
            <Field>
              Twitter:{" "}
              <FieldValue>
                <a
                  href={ensureUrlScheme(formData.twitter)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {formData.twitter}
                </a>
              </FieldValue>
            </Field>
          )}
          {formData.instagram && (
            <Field>
              Instagram:{" "}
              <FieldValue>
                <a
                  href={ensureUrlScheme(formData.instagram)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {formData.instagram}
                </a>
              </FieldValue>
            </Field>
          )}
          {formData.linkedin && (
            <Field>
              LinkedIn:{" "}
              <FieldValue>
                <a
                  href={ensureUrlScheme(formData.linkedin)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {formData.linkedin}
                </a>
              </FieldValue>
            </Field>
          )}
        </Box>
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button variant="contained" color="primary" onClick={saveAsVCard}>
            Save as vCard
          </Button>
          <Button variant="contained" color="secondary" onClick={saveAsPDF}>
            Save as PDF
          </Button>
        </Box>
      </StyledPaper>
    </StyledContainer>
  );
};

export default DisplayPage;
