import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  Paper,
  CssBaseline,
  useMediaQuery,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import QRCodeStyling from "qr-code-styling";
import { jsPDF } from "jspdf";
import { storage, db } from "./firebase"; // Ensure correct path
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, addDoc } from "firebase/firestore";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import styled from "styled-components";
import SecondHeader from "./SecondHeader";
import { saveAs } from "file-saver"; // Ensure this is imported

const StyledContainer = styled(Container)`
  width: 1200px !important;
  padding: 20px;
  border-radius: 10px;
  @media (max-width: 1200px) {
    width: auto !important;
    padding: 10px;
  }
`;

const StyledPaper = styled(Paper)`
  padding: 20px;
  margin-top: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

const StyledButton = styled(Button)`
  background-color: #5956e9 !important;
  margin-top: 20px !important;
  color: white;
  &:hover {
    background-color: #45a049;
  }
`;

const QRCodeContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 250px;
  border: 1px dashed #5956e9;
  position: relative;
`;

const PlaceholderText = styled(Typography)`
  position: absolute;
  text-align: center;
  color: #999;
`;

const QRGenerator = () => {
  const isMobile = useMediaQuery("(max-width:600px)");

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    designation: "",
    organization: "",
    phone1: "",
    phone2: "",
    email: "",
    website: "",
    address: "",
    googleMapURL: "",
    facebook: "",
    twitter: "",
    instagram: "",
    linkedin: "",
    color: "#464ea4",
    logo: null,
  });

  const [qrValue, setQrValue] = useState("");
  const qrCodeRef = useRef(null);
  const qrCode = useRef(
    new QRCodeStyling({
      width: 300,
      height: 300,
      type: "svg", // Generate as SVG to keep it vector-based
      dotsOptions: { color: "#000000", type: "square" },
      imageOptions: { crossOrigin: "anonymous" },
    })
  );

  useEffect(() => {
    if (qrCodeRef.current) {
      qrCode.current.append(qrCodeRef.current);
    }
  }, []);

  useEffect(() => {
    if (qrValue) {
      qrCode.current.update({
        data: qrValue,
        dotsOptions: { color: formData.color, type: "square" },
        image: formData.logo ? URL.createObjectURL(formData.logo) : undefined,
      });
    }
  }, [qrValue, formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePhoneChange = (value, name) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleDrop = (acceptedFiles) => {
    setFormData({ ...formData, logo: acceptedFiles[0] });
  };

  const validateURL = (url) => {
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(url);
  };

  const validateEmail = (email) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  const handleGenerate = async () => {
    // Validate email
    if (formData.email && !validateEmail(formData.email)) {
      alert("Invalid email format");
      return;
    }

    // Validate URLs
    const urlFields = [
      "website",
      "googleMapURL",
      "facebook",
      "twitter",
      "instagram",
      "linkedin",
    ];
    for (const field of urlFields) {
      if (formData[field] && !validateURL(formData[field])) {
        alert(`Invalid URL format in ${field}`);
        return;
      }
    }

    const queryParams = new URLSearchParams(formData).toString();
    const url = `${window.location.origin}/display?${queryParams}`;
    setQrValue(url);

    if (formData.logo) {
      try {
        const downloadURL = await uploadLogo(formData.logo);
        await addDoc(collection(db, "qrCodes"), {
          ...formData,
          logo: downloadURL, // Store the download URL instead of the File object
        });
        //alert("Data stored successfully");
      } catch (error) {
        console.error("Error storing data: ", error);
        // alert("Error storing data: " + error.message);
      }
    } else {
      try {
        await addDoc(collection(db, "qrCodes"), formData);
        // alert("Data stored successfully without logo");
      } catch (error) {
        console.error("Error storing data: ", error);
        // alert("Error storing data: " + error.message);
      }
    }
  };

  const uploadLogo = async (file) => {
    try {
      // Create a storage reference
      const logoRef = ref(storage, `logos/${file.name}`);

      // Upload the file
      await uploadBytes(logoRef, file);

      // Get the file's download URL
      const downloadURL = await getDownloadURL(logoRef);

      return downloadURL;
    } catch (error) {
      console.error("Error uploading logo:", error);
      throw error;
    }
  };

  const downloadQRCode = async (format) => {
    try {
      // Use a delay to ensure the QR code is fully rendered
      await new Promise(resolve => setTimeout(resolve, 500));

      if (format === "pdf") {
        const svgData = await qrCode.current.getRawData('svg');
        const svgBlob = new Blob([svgData], { type: "image/svg+xml" });
        const imgUrl = URL.createObjectURL(svgBlob);

        const img = new Image();
        img.src = imgUrl;

        img.onload = () => {
          const canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);

          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF();
          pdf.addImage(imgData, "PNG", 15, 40, 180, 180);
          pdf.save("qr-code.pdf");
        };
      } else if (format === "eps") {
        const svgData = await qrCode.current.getRawData('svg');
        const svgBlob = new Blob([svgData], { type: "image/svg+xml" });
        saveAs(svgBlob, "qr-code.eps");
      } else {
        qrCode.current.download({ name: "qr-code", extension: format });
      }
    } catch (error) {
      console.error("Error generating QR code:", error);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop: handleDrop });

  return (
    <>
      <CssBaseline />
      <StyledContainer maxWidth="lg">
        <StyledPaper elevation={3}>
          <SecondHeader />
          <Typography
            variant="h6"
            align="left"
            gutterBottom
            style={{
              display: "flex",
              paddingTop: "50px",
              paddingLeft: "20px",
              fontFamily: "rubik",
            }}
          >
            ENTER CONTENT
          </Typography>
          <Grid
            container
            spacing={3}
            style={{ paddingLeft: "20px", paddingBottom: "40px" }}
          >
            <Grid item xs={12} md={6}>
              <Box component="form" noValidate autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="First Name"
                      variant="outlined"
                      id="firstname"
                      name="firstname"
                      value={formData.firstname}
                      onChange={handleChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Last Name"
                      variant="outlined"
                      id="lastname"
                      name="lastname"
                      value={formData.lastname}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Designation"
                      variant="outlined"
                      id="designation"
                      name="designation"
                      value={formData.designation}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Organization"
                      variant="outlined"
                      id="organization"
                      name="organization"
                      value={formData.organization}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <PhoneInput
                      country={"ae"}
                      value={formData.phone1}
                      onChange={(value) => handlePhoneChange(value, "phone1")}
                      inputStyle={{ width: "100%" }}
                      containerStyle={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <PhoneInput
                      country={"ae"}
                      value={formData.phone2}
                      onChange={(value) => handlePhoneChange(value, "phone2")}
                      inputStyle={{ width: "100%" }}
                      containerStyle={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Email"
                      variant="outlined"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Website"
                      variant="outlined"
                      id="website"
                      name="website"
                      type="url"
                      value={formData.website}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Address"
                      variant="outlined"
                      id="address"
                      name="address"
                      value={formData.address}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Google Map URL"
                      variant="outlined"
                      id="googleMapURL"
                      name="googleMapURL"
                      type="url"
                      value={formData.googleMapURL}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Facebook"
                      variant="outlined"
                      id="facebook"
                      name="facebook"
                      type="url"
                      value={formData.facebook}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Twitter"
                      variant="outlined"
                      id="twitter"
                      name="twitter"
                      type="url"
                      value={formData.twitter}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Instagram"
                      variant="outlined"
                      id="instagram"
                      name="instagram"
                      type="url"
                      value={formData.instagram}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="LinkedIn"
                      variant="outlined"
                      id="linkedin"
                      name="linkedin"
                      type="url"
                      value={formData.linkedin}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography
                      variant="h6"
                      align="left"
                      gutterBottom
                      style={{ fontFamily: "rubik" }}
                    >
                      Choose QR Color
                    </Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="color"
                        id="color"
                        name="color"
                        value={formData.color}
                        onChange={handleChange}
                        style={{
                          width: "40%",
                          height: "60px",
                          cursor: "pointer",
                          padding: "0", // Ensure no padding
                          border: "none", // Ensure no border if you don't want any space caused by border
                          margin: "0", // Ensure no margin
                        }}
                      />
                      <input
                        type="text"
                        value={formData.color}
                        readOnly
                        style={{
                          height: "55px",
                          cursor: "pointer",
                          border: "1px solid #ccc",
                          textAlign: "center",
                          width: "60%",
                          outline: "none",
                          padding: "0", // Ensure no padding
                          margin: "0", // Ensure no margin
                          borderLeft: "none", // Remove left border to avoid double border effect
                        }}
                        onFocus={(e) => (e.target.style.borderColor = "#ccc")} // Remove border color on focus
                        onBlur={(e) => (e.target.style.borderColor = "#ccc")}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div
                      {...getRootProps({ className: "dropzone" })}
                      style={{
                        border: "1px dashed #5956E9",
                        padding: "10px",
                        textAlign: "left",
                        cursor: "pointer",
                        width: "100%",
                      }}
                    >
                      <input {...getInputProps()} />
                      {formData.logo ? (
                        <Typography variant="body1">
                          {formData.logo.name}
                        </Typography>
                      ) : (
                        <Typography
                          variant="h6"
                          align="left"
                          gutterBottom
                          style={{
                            fontFamily: "rubik",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src="icon3.png"
                            alt=""
                            style={{
                              backgroundColor: "#5956E9",
                              marginRight: "10px",
                              height: "30px",
                              width: "30px",
                            }}
                          />
                          Add Your Logo Image
                        </Typography>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <QRCodeContainer
                sx={{
                  marginLeft: { xs: "12px", sm: "110px" }, // Apply margin on small screens and above
                  marginTop: { xs: "10px", sm: "0px" }, // Apply margin on small screens and above
                  marginBottom: { xs: "10px", sm: "0px" }, // Apply margin on small screens and above
                }}
              >
                <div ref={qrCodeRef}></div>
                {!qrValue && (
                  <PlaceholderText
                    variant="body1"
                    style={{ fontFamily: "rubik" }}
                  >
                    Your QR will appear here
                  </PlaceholderText>
                )}
              </QRCodeContainer>
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    marginLeft: { xs: "0px", sm: "70px" },
                    marginTop: { xs: "0px", sm: "10px" }, // Apply margin on small screens and above
                  }}
                >
                  <StyledButton
                    fullWidth={false}
                    variant="contained"
                    onClick={handleGenerate}
                  >
                    Generate QR
                  </StyledButton>
                </Grid>
              </Grid>
              {qrValue && (
                <Box
                  mt={2}
                  display="flex"
                  justifyContent={isMobile ? "space-between" : "space-around"}
                  width="80%"
                >
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#5956E9" }}
                    onClick={() => downloadQRCode("png")}
                    sx={{
                      marginLeft: { xs: "0px", sm: "90px" }, // Apply margin on small screens and above
                    }}
                  >
                    .PNG
                  </Button>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#5956E9" }}
                    onClick={() => downloadQRCode("svg")}
                    sx={{
                      marginLeft: { xs: "10px", sm: "0px" }, // Apply margin on small screens and above
                    }}
                  >
                    .SVG
                  </Button>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#5956E9" }}
                    onClick={() => downloadQRCode("pdf")}
                    sx={{
                      marginLeft: { xs: "10px", sm: "0px" }, // Apply margin on small screens and above
                    }}
                  >
                    .PDF
                  </Button>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#5956E9" }}
                    onClick={() => downloadQRCode("eps")}
                    sx={{
                      marginLeft: { xs: "10px", sm: "0px" }, // Apply margin on small screens and above
                    }}
                  >
                    .EPS
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>
        </StyledPaper>
      </StyledContainer>
    </>
  );
};

export default QRGenerator;
